<template>
  <div class="min-h-screen min-w-full flex flex-col items-stretch bg-rad">
    <LayoutHeader />
    <slot />
    <LayoutFooter />
  </div>
</template>

<script setup lang="ts">
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////// IMPORTS //////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// LIBRARIES
// COMPONENTS
import LayoutHeader from "~/components/Modules/Layout/Header.vue";
import LayoutFooter from "~/components/Modules/Layout/Footer.vue";

// TYPES
// SERVICES
import { prepareVersion } from "~/services/prepareGeneral";

// COMPOSABLES
const { fetchVersion } = generalFetch();

// STORES
const mainStore = useMainStore();

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////// LOGIC /////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// DEFINE & DECLARE
// USE
const { app } = useRuntimeConfig();
const { backendUrl, backendVersion } = app;

// REF
// COMPUTED
// BASIC LOGIC
const { versionURL } = prepareVersion(backendUrl, backendVersion);
const appVersion = await fetchVersion(versionURL);

mainStore.setAppVersion(appVersion as string);

// MOUNTED
// UNMOUNTED‚
</script>

<style lang="scss"></style>
